import React, { Fragment } from 'react'
import { graphql, StaticQuery } from 'gatsby'
import Link from 'gatsby-link'
import { MDBAnimation, MDBRow, MDBCol } from 'mdbreact'
const _ = require('lodash')

const BlogTags = ({ btnActive, onButtonClick }) => {
  const btnLimit = 14

  const handleClick = (tag) => {
    onButtonClick(tag)
  }

  return (
    <StaticQuery
      query={graphql`
        query TagsQuery {
          allMarkdownRemark(
            sort: { fields: [frontmatter___tags], order: ASC }
            filter: { frontmatter: { template: { eq: "blog" } } }
          ) {
            tags: group(field: frontmatter___tags) {
                tag: fieldValue
                count: totalCount
              }
            edges {
              node {
                frontmatter {
                  tags
                }
              }
            }
          }
        }
      `}

    render={data => (
      <MDBRow className="pb-5"> 
        <MDBCol md="12" className="pt-5">
          <MDBAnimation reveal type="fadeIn" duration="1587ms">
            {data.allMarkdownRemark.tags.slice(0,btnLimit <= data.allMarkdownRemark.tags.length ? btnLimit : data.allMarkdownRemark.tags.length).map((tag, index) => (
              <Fragment key={index}>
                {!!tag.tag &&
                  <Link 
                    to={`/blog/tags/${_.kebabCase(tag.tag)}/`} 
                    // onClick={() => { sessionStorage.setItem('blogQuery', '') }}
                    // onClick={() => { if (onButtonClick) { onButtonClick(tag.tag) }}}
                    onClick={() => { handleClick(tag.tag) }}
                    className={btnActive === tag.tag ? "btn Ripple-parent btn-mdb-select btn-sm btn-sm-block active" : "btn Ripple-parent btn-mdb-select btn-tag btn-sm btn-sm-block"} 
                  >
                    {`${tag.tag} (${tag.count})`}
                  </Link>
                }
              </Fragment>
            ))}
            {btnLimit < data.allMarkdownRemark.tags.length && <Link to={`/blog/tags/`} className="btn Ripple-parent btn-tag-all btn-mdb-select btn-sm btn-sm-block" >--- See all tags ---</Link>}
          </MDBAnimation>
        </MDBCol>
      </MDBRow>
    )
  }
  />
)}
export default BlogTags