import React, { useEffect }  from 'react'
import { Link, graphql, navigate } from 'gatsby'
import { MDBContainer, MDBRow, MDBCol } from 'mdbreact'
import Layout from '../components/layout'
import Hero from '../components/hero'
import SEO from '../components/seo'
import ColumnAlternatingStyled from '../components/columnAlternatingStyled'
import BlogCardItems from '../components/cardBlog'
import BlogTags from '../components/blogTags'
import BlogItem from '../components/blogItem'
import ClientSearch from '../components/ClientSearch'
import moment from 'moment'


const BlogPageRoute = (props) =>  {
  const { pageContext } = props
  const { contentData } = pageContext
  const { allData, options } = contentData  

  const posts = props.data.allMarkdownRemark.edges
  const heroImage = props.data.heroImage

  const { pagePath } = pageContext
  const { currentPage, numPages } = pageContext
  const isFirst = currentPage === 1
  const isLast = currentPage === numPages
  const prevPage = (currentPage - 1).toString()
  const nextPage = (currentPage + 1).toString()

  const handleTagClick = (tag) => {
    sessionStorage.setItem('blogQuery', '')
  }

  const getPaginationPages = numPages => {
    let content = []
    for (let i = 1; i <= numPages; i++) {
      content.push(<option key={i} value={i} aria-label={`Go to page ${i}`} aria-current="false" >{i}</option>)
    }
    return content
  }

  const getPaginationLinks = numPages => {
    let content = []
    for (let i = 1; i <= numPages; i++) {
      if (currentPage !== i) {
        content.push(<a href={i == 1 ? `/blog/` : `/blog/page/${i}/`} key={i}>{`Blog: Page ${i}`}</a>)
      }
    }
    return content
  }

  const changePage = e => {
    navigate( e.target.value && e.target.value !== "1" ? `${pagePath}${e.target.value}/` : `/blog/` )
  }


  useEffect(() => {
    const handleResize = () => { 
      const searchElement = document.getElementById('search')
      if (searchElement) {
        if (searchElement.clientHeight > 140) { 
          document.getElementById('blog-container-pagination').style.display = "none"
        } else {
          document.getElementById('blog-container-pagination').style.display = ""
        }
      }
    }

    const resizeObserver = new ResizeObserver(handleResize)

    const searchElement = document.getElementById('search')
    if (searchElement) {
      resizeObserver.observe(searchElement)
    }

    // Cleanup function to disconnect the observer (important!)
    return () => {
      if (searchElement) {
          resizeObserver.unobserve(searchElement)
      }
    }
  }, [props.content])

  return (
    <Layout>
      <div id="sub-page">
        <Hero
          class="intro-50"
          image={heroImage.childImageSharp.gatsbyImageData}
          alt="UNICOM Engineering blog for global OEM technology updates and insights"
          title="UNICOM Engineering blog"
          subtitle="OEM technology updates and insights."
          type="blog"
        />

        <main>
          <section id="main" className="bg-white pt-4 pb-3">
            <MDBContainer>
              <BlogTags onButtonClick={handleTagClick} />

              <MDBRow id="search">
                <MDBCol>
                  <ClientSearch content={allData} engine={options} context="blog" />
                </MDBCol>
              </MDBRow>
            </MDBContainer>
          </section>

          <section id="blog-container-pagination" className="bg-white pt-0 mt-0">
            <MDBContainer>
              <div >
                {currentPage === 1 ?
                // Render page 1 with cards for first 3 items and list of remaining items for page 1
                <div className="pt-5">
                  {posts.slice(0, 1).map(({ node }, index) => (
                    <ColumnAlternatingStyled
                      key={index}
                      textCol="7"
                      imageCol="5"
                      title={node.frontmatter.title}
                      subtitle={node.frontmatter.subtitle}
                      subtitlecolour={null}
                      description={node.frontmatter.description}
                      image={node.frontmatter.imageFeatured ? node.frontmatter.imageFeatured.childImageSharp.gatsbyImageData : node.frontmatter.image.childImageSharp.gatsbyImageData}
                      alt={node.frontmatter.alttext}
                      placement="right"
                      titleClass="font-alt font-w-400 letter-spacing-1 mb-3 title-xs-medium title-medium text-very-dark-blue"
                      link={node.fields.slug}
                      date={moment(node.frontmatter.date).format('YYYY/MM/DD')} 
                      author={node.frontmatter.author}
                      readtime={node.frontmatter.readtime}   
                      />
                  ))}
                  
                  <MDBRow>
                    {posts.slice(1,3).map(({ node }, index) => (
                      <MDBCol key={index} md="12" lg="6" className="d-flex">
                        <BlogCardItems
                          key={index}
                          collg="6"
                          colmd="6"
                          link={node.fields.slug}
                          image={node.frontmatter.image.childImageSharp.gatsbyImageData}
                          alt={node.frontmatter.alttext}
                          title={node.frontmatter.title}
                          description={node.frontmatter.description.length <= 160 ? node.frontmatter.description : node.frontmatter.description.substr(0, 160) + '...'}
                          date={node.frontmatter.date}
                          author={node.frontmatter.author}
                          readtime={node.frontmatter.readtime}
                        />
                      </MDBCol>
                    ))}
                  </MDBRow>

                  {posts.slice(3).map(({ node }, index) => (
                    <BlogItem data={node} key={index}/>
                  ))}
                </div>

                : 
                // return full page of items in list form when page != 1
                <>
                  {posts.map(({ node }, index) => (
                    <BlogItem data={node} key={index} />
                  ))}
                </>

              }


              <MDBRow className="pagination pt-4 mt-5">
                {!isFirst && (
                  <MDBCol className="text-left">
                    <Link to={currentPage === 2 ? `/blog/` : `${pagePath}${prevPage}/`} rel="prev" className="linkedin">
                      ← Newer posts
                    </Link>
                  </MDBCol>
                )}
                {!isLast && (
                  <MDBCol className="text-right">
                    <Link to={`${pagePath}${nextPage}/`} rel="next" className="linkedin">
                      Older posts →
                    </Link>
                  </MDBCol>
                )}
              </MDBRow>

              <MDBRow className="pt-3">
                <MDBCol className="text-right">
                  <span>Showing page </span> 
                  <select 
                    aria-label="Pagination dropdown"
                    value={currentPage === 1 ? `` : currentPage.toString()}
                    onChange={changePage}
                  >
                    {getPaginationPages(numPages)}
                  </select>
                  <span> of {numPages}</span>
                </MDBCol>
              </MDBRow>
                <div style={{display: "none"}}>
                  {getPaginationLinks(numPages)}
                </div>
              </div>
            </MDBContainer>
          </section>
        </main>
      </div>
    </Layout>
  )
}
export default BlogPageRoute

export function Head({ data, location, pageContext }) {
  const heroImage = data.heroImage
  const { currentPage } = pageContext

  return (
    <SEO
      title={`Blogs` + ((currentPage > 1) ? ' (' + currentPage + ')' : '')}
      description={`OEM technology updates and insights` + ((currentPage > 1) ? ': page ' + currentPage : '')}
      url={location.pathname} 
      image={heroImage.childImageSharp?.gatsbyImageData?.images?.fallback?.src}
      canonical={location.pathname}                  
    />
  )
}

export const tagPageQuery = graphql`
  query BlogPageItems($skip: Int!, $limit: Int!) {
    site {
        siteMetadata {
          title
          siteUrl
        }
    }
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { template: { eq: "blog" } } }
      limit: $limit
      skip: $skip 
    ) {
      totalCount
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            description 
            date
            author
            readtime
            image {
              childImageSharp {
                gatsbyImageData(width: 960, quality: 90)
              }
            }
            alttext
            imageFeatured {
              childImageSharp {
                gatsbyImageData(width: 900, quality: 90)
              }
            }
            tags
          }
        }
      }
    }
    heroImage: file(name: { eq: "blog-hero-image" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 90)
      }
    }  
  }
`